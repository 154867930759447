jQuery(function ($) {
  //------------------------------------------------------ Landing Page
  $(".menu__main li").on("click", function () {
    $(".menu__main li").removeClass("active");
    $(this).addClass("active");
  });

  if (!!window.IntersectionObserver) {
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(".menu__main a").each(function () {
            const $this = $(this),
              $itemLink = $this.attr("class");
            if ($itemLink == entry.target.id) {
              $(".menu__main a").removeClass("active");
              $this.addClass("active");
            }
          });
        } else {
          $(".menu__main li").removeClass("active");
        }
      });
    }, {});

    let target = document.querySelectorAll(".section");
    target.forEach((el) => {
      observer.observe(el);
    });
  }

  //------------------------------------------------------ MENU FIXED

  fixedHeader();

  //---------------------------------- resize
  $(window).resize(function () {
    if ($(window).width() <= 550) {
      fixedHeader();
    }
  });

  function fixedHeader() {
    $(window).load(function () {
      if ($(window).scrollTop() != 0) {
        $(".header").addClass("header--fixed header--hidden");
      }
    });

    let $header = $(".header");
    let $finalPosition;
    let $heightMargin = -50;
    let $heightStart = $header.height() + $heightMargin;

    $(window).scroll(function () {
      let $scrollPosition = $(window).scrollTop();
      let $currentPosition = $scrollPosition;

      if ($currentPosition == 0) {
        $header.removeClass("header--fixed");
      } else if (
        $finalPosition < $currentPosition &&
        $currentPosition > $heightStart
      ) {
        $header.addClass("header--hidden header--fixed");
        $(".header__menu").removeClass("header__menu--active");
        $("#menu__btn").removeClass("menu__btn--active");
      } else {
        $header.removeClass("header--hidden");
      }
      $finalPosition = $currentPosition;
    });
  }

  //------------------------------------------------------ Bouton menu click
  $("#menu__btn").on("click", function () {
    $(".header__menu").toggleClass("header__menu--active");
    $(this).toggleClass("menu__btn--active");
    $("body").toggleClass("menu__body--active");
  });

  $(document).on("mouseup", function (e) {
    if (!e.target.closest(".header__menu")) {
      $(".header__menu").removeClass("header__menu--active");
      $("#menu__btn").removeClass("menu__btn--active");
      $("body").removeClass("menu__body--active");
    }
  });
  // ------------------------------------------- nav child
  $(".parent").click(function () {
    $(".nav-child").removeClass("nav-child--active");
    $(this).find(".nav-child").toggleClass("nav-child--active");
  });

  $(document).on("mouseup", function (e) {
    if (!e.target.closest(".parent")) {
      $(".nav-child").removeClass("nav-child--active");
    }
  });
});
